import React from "react"
import porductimg1 from "../../Asstes/Product-Section-img-2/img-4.jpg"
import porductimg2 from "../../Asstes/Product-Section-img-2/img-5.jpg"
import porductimg3 from "../../Asstes/Product-Section-img-2/img-6.jpg"
import porductimg4 from "../../Asstes/Product-Section-img-2/img-3.jpg"
import porductimg5 from "../../Asstes/Product-Section-img-2/img-2.jpg"
import porductimg6 from "../../Asstes/Product-Section-img-2/img-2.jpg"

const ProductCardTwo =()=>{
    return(
        <>
              <div class="grid min-h-[140px]  w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible ">
             <h1 className="mb-1 text-main p-2 text-5xl font-medium leading-tight text-primary mt-5">
          Organic Products
        </h1>
        <p className="text-sm text-subMain ">
        Explore our range of health-focused organic products for <br /> <span className="text-center">wellness.</span> 
        </p>
             <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-24 flex items-center">
     
     <div className="-m-1 flex flex-row sm:flex-wrap md:-m-2">
       <div className="flex w-full sm:w-1/2 flex-wrap lg:flex-row flex-row-reverse">
         <div className="w-full lg:w-1/2 p-1 md:p-2">
           <img
             alt="gallery"
             className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
             src={porductimg1}
           />
         </div>
         <div className="w-full lg:w-1/2 p-1 md:p-2">
           <img
             alt="gallery"
             className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
             src={porductimg2}
           />
         </div>
         <div className="w-full p-1 md:p-2">
           <img
             alt="gallery"
             className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center max-h-none lg:max-h-[1000px]"
             src={porductimg3}
           />
         </div>
       </div>
       <div className="flex w-full sm:w-1/2 flex-wrap">
         <div className="w-full p-1 md:p-2">
           <img
             alt="gallery"
             className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
             src={porductimg4}
           />
         </div>
         <div className="w-1/2 p-1 md:p-2">
           <img
             alt="gallery"
             className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
             src={porductimg5}
           />
         </div>
         <div className="w-1/2 p-1 md:p-2">
           <img
             alt="gallery"
             className="block h-full w-full rounded-lg 2xl:rounded-2xl object-cover object-center"
             src={porductimg6}
           />
         </div>
       </div>
     </div>
   </div>


   <>
  {/* component */}
 
 
</>

             </div>
        </>
    )
}
export default ProductCardTwo