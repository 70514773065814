import { IoPhonePortraitOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { FaCode } from "react-icons/fa";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { useState } from "react";
const Contact = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "88f47d50-ed6a-43cb-acda-5f07c72bf067");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <>
      <div className=" py-24  dark:bg-gray-900">
        <div className="container  flex flex-col items-center justify-center w-full p-6 mx-auto text-center xl:px-0">
          <h1 className="mb-1 text-main p-2 md:mt-5 text-5xl font-medium leading-tight text-primary mt-5">
            Get in Touch with <br /> Organicz
          </h1>
          <p className="text-sm text-subMain ">
            Reach out for inquiries about our health-focused organic <br />{" "}
            products.
          </p>
          <section className="mb-32 mt-7">
            <div
              id="map"
              className="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13993.109359253684!2d77.3143567!3d28.7411354!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xac102df52baac4b5%3A0x991c612c41cbf082!2sOrganicz!5e0!3m2!1sen!2sin!4v1728362191674!5m2!1sen!2sin"
                width="100%"
                height={480}
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="container px-6 md:px-12">
              <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px] border border-gray-300">
                <div className="flex flex-wrap">
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
                    <form onSubmit={onSubmit}>
                      <div
                        className="relative mb-6"
                        data-te-input-wrapper-init=""
                      >
                        <input
                          type="text"
                          name="name"
                          className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none "
                          id="exampleInput90"
                          placeholder="Name"
                          required
                        />
                        <label
                          className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none "
                          htmlFor="exampleInput90"
                        >
                          Name
                        </label>
                      </div>
                      <div className="relative mb-6">
                        <input

                          type="email"
                          name="email"
                          className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none "
                          required
                        />
                        <label
                          className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none "
                          htmlFor="exampleInput91"
                        >
                          Email address
                        </label>
                      </div>
                      <div
                        className="relative mb-6"
                        data-te-input-wrapper-init=""
                      >
                        <textarea
                          name="message"
                          className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none "
                          id="exampleFormControlTextarea1"
                          rows={3}
                          defaultValue={""}
                          required
                        />
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none "
                        >
                          Message
                        </label>
                      </div>
                      <span>{result}</span>
                      <div className="mb-6 inline-block min-h-[1.5rem] justify-center pl-[1.5rem] md:flex"></div>
                      <button
                        type="submit"
                        className="mb-6 w-full rounded bg-sky-500 bg-main text-white px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal   lg:mb-0"
                      >
                        Send
                      </button>
                    </form>
                  </div>
                  <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                    <div className="flex flex-wrap">
                      <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                        <div className="flex items-start">
                          <div className="shrink-0">
                            <div className="inline-block rounded-md bg-sky-200 p-4 text-primary">
                              <IoPhonePortraitOutline size={30} />
                            </div>
                          </div>
                          <div className="ml-6 grow">
                            <p className="mb-2 font-bold ">Mobile</p>
                            <p className="text-sm text-neutral-500">
                              <Link to="tel:+91 8287486509">
                                +91 8287486509
                              </Link>
                            </p>
                            <p className="text-sm text-neutral-500">
                              <Link to="tel:+91 9717568469">
                                +91 9717568469
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                        <div className="flex items-start">
                          <div className="srink-0">
                            <div className="inline-block rounded-md bg-sky-200 p-4 text-primary">
                              <HiOutlineBuildingOffice size={30} />
                            </div>
                          </div>
                          <div className="ml-6 grow">
                            <p className="mb-2 font-bold ">Address</p>
                            <p className="text-sm text-neutral-500">
                              Sai enclave, 1632, Banthla, <br />
                              Ghaziabad, Uttar Pradesh 201102 <br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:mb-12 lg:w-full lg:px-6 xl:w-6/12">
                        <div className="align-start flex">
                          <div className="shrink-0">
                            <div className="inline-block rounded-md bg-sky-200 p-4 text-primary">
                              <IoIosMail size={30} />
                            </div>
                          </div>
                          <div className="ml-6 grow">
                            <p className="mb-2 font-bold ">Email</p>
                            <p className="text-neutral-500">
                              {" "}
                              <Link to="mailto:max.organiczindia@gmail.com?body=My custom mail body">
                              organiczindia@gmail.com
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-12 xl:w-6/12">
                        <div className="align-start flex">
                          <div className="shrink-0">
                            <div className="inline-block rounded-md bg-sky-200 p-4 text-primary">
                              <FaCode size={30} />
                            </div>
                          </div>
                          <div className="ml-6 grow">
                            <p className="mb-2 font-bold  "> Code by </p>
                            <p className="text-neutral-500">
                              {" "}
                              <Link
                                target="_blank"
                                to="https://vikash-singh-rajput.netlify.app/"
                              >
                                Vikash Singh Rajput
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Contact;
