import React from "react";
import product1 from '../Asstes/product-1.jpg'
import product2 from '../Asstes/product-6.jpg'
import product3 from '../Asstes/product-7.jpg'
import product4 from '../Asstes/product-7.jpg'
const CardOne = () => {
  return (
    <>
      <div className="grid lg:p-5 md:p-0  mx-auto w-full place-items-center  overflow-hidden   bg-SubTwo">
        <h1 className="mb-1 text-white p-2 text-5xl font-medium leading-tight text-primary mt-5">
          Organic Products
        </h1>
        <p className="text-sm text-white mb-10">
          Explore our range of health-focused organic products for <br />
        </p>
        <div className="mx-w-[1320px] mx-auto grid lg:grid-cols-2 md:grid-cols-1 items-center  gap-1    ">
        <div data-aos="fade-right">
          <div className="">
            <img
              class="object-cover h-80 md:h-70 max-w-full rounded-2xl  "
              src={product4}
              alt=""
            />
            <div className="">
              <h1 className="text-2xl mt-5  dark:text-white text-white">
                {" "}
                Health Essentials <span className=" w-4 h-5 ">
                  →
                </span>{" "}
              </h1>

              <p className="text-sm text-white">
                Discover our best-selling health essentials, carefully crafted
                to <br />
                support your
              </p>
            </div>
          </div>
          </div>
          <div data-aos="fade-left">

          <div>
            <img
              class="object-cover h-80  max-w-full rounded-md "
              src={product2}
              alt="product"
            />
            <div className="">
              <h1 className="text-2xl mt-5 dark:text-white text-white">
                {" "}
                Health Essentials <span className=" w-4 h-5">
                  →
                </span>{" "}
              </h1>

              <p className="text-sm text-white">
                Discover our best-selling health essentials, carefully crafted
                to support your
              </p>
            </div>
          </div>
          </div>
          <div data-aos="fade-right">
          <div className="mt-6 ">
            <img
              class="object-cover h-80  max-w-full rounded-lg "
              src={product3}
              alt=""
            />
            <div className="">
              <h1 className="text-2xl mt-5 dark:text-white text-white">
                {" "}
                Health Essentials <span className=" w-4 h-5">
                  →
                </span>{" "}
              </h1>

              <p className="text-sm text-white">
                Discover our best-selling health essentials, carefully crafted
                to <br /> support your
              </p>
            </div>
          </div>
          </div>
          <div data-aos="fade-left">
          <div className="mt-6">
            <img
              class="object-cover h-80  max-w-full rounded-lg "
              src={product1}
              alt=""
            />
            <div className="">
              <h1 className="text-2xl mt-5 dark:text-white text-white">
                {" "}
                Health Essentials <span className=" w-4 h-5">
                  →
                </span>{" "}
              </h1>

              <p className="text-sm text-white">
                Discover our best-selling health essentials, carefully crafted
                to <br /> support your
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CardOne;
