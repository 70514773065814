import React from "react"
import ProductCardOne from "./ProductCardOne"
import ProductCardTwo from "./ProductCardTwo"
import Contact from "../contact/Contact"

const Product =()=>{
    return(
        <>
     <ProductCardOne/>
     <ProductCardTwo/>
     <Contact/>
        </>
    )
}
export default Product