import React, { } from "react";
import user1  from '../Asstes/user-1.jpg'
import user2  from '../Asstes/user-2.jpg'

import 'swiper/css';
const Review =()=>{
 
  
    return(
<>
<div className="py-5 dark:bg-gray-900">
  <div className="container flex flex-col items-center justify-center w-full p-6 mx-auto text-center xl:px-0">
    <div className="text-sm font-bold tracking-wider text-indigo-600 uppercase">
  
    </div>
    <h2 className="max-w-2xl mt-3 text-3xl font-bold leading-snug tracking-tight text-main lg:leading-tight lg:text-4xl dark:text-white">
      Here's what our customers said
    </h2>
    <p className="max-w-2xl py-4 text-lg leading-normal text-gray-500 lg:text-xl xl:text-xl dark:text-gray-300">
      Testimonials is a great way to increase brand trust and awareness. Use
      this section to highlight your popular customers.
    </p>
  </div>
  <div data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">

  <div className="container p-6 mx-auto mb-10 xl:px-0">
    <div className="grid gap-10 lg:grid-cols-2 xl:grid-cols-3">
      <div className="lg:col-span-2 xl:col-auto">
<div data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
</div>
        <div className="flex flex-col justify-between w-full h-full px-6 py-6 bg-gray-100 dark:bg-gray-800 md:px-14 rounded-2xl md:py-14 dark:bg-trueGray-800">
          <p className="text-2xl leading-normal dark:text-gray-300">
            Share a{" "}
            <mark className="mx-1 text-indigo-800 bg-indigo-100 rounded-md ring-indigo-100 ring-4 dark:ring-indigo-900 dark:bg-indigo-900 dark:text-indigo-200">
              testimonial
            </mark>{" "}
            that hits some of your benefits from one of your popular customers.
          </p>
          <div className="flex items-center mt-8 space-x-3">
            <div className="flex-shrink-0 overflow-hidden rounded-full w-14 h-14">
              <img
                alt="Avatar"
                src={user1}
                loading="lazy"
              />
            </div>
            <div>
              <div className="text-lg font-medium text-subMain">
              <div className="text-lg font-medium text-subMain">
              Vishal Gupta
              </div>
              </div>
              <div className="text-gray-600 dark:text-gray-400">
               
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="">
        <div className="flex flex-col justify-between w-full h-full px-6 py-6 bg-gray-100 dark:bg-gray-800 md:px-14 rounded-2xl md:py-14 dark:bg-trueGray-800">
          <p className="text-2xl leading-normal dark:text-gray-300">
            Make sure you only pick the{" "}
            <mark className="mx-1 text-indigo-800 bg-indigo-100 rounded-md ring-indigo-100 ring-4 dark:ring-indigo-900 dark:bg-indigo-900 dark:text-indigo-200">
              right sentence
            </mark>{" "}
            to keep it short and simple.
          </p>
          <div className="flex items-center mt-8 space-x-3">
            <div className="flex-shrink-0 overflow-hidden rounded-full w-14 h-14">
              <img
                alt="Avatar"
                src={user2}
                loading="lazy"
              />
            </div>
            <div>
              <div className="text-lg font-medium text-subMain">
          Ranjan Kumar
              </div>
              <div className="text-gray-600 dark:text-gray-400">
             
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="flex flex-col justify-between w-full h-full px-6 py-6 bg-gray-100 dark:bg-gray-800 md:px-14 rounded-2xl md:py-14 dark:bg-trueGray-800">
          <p className="text-2xl leading-normal dark:text-gray-300">
            This is an{" "}
           
            I recently started incorporating chia seeds into my diet, and I couldn’t be 
            <mark className="mx-1 text-indigo-800 bg-indigo-100 rounded-md ring-indigo-100 ring-4 dark:ring-indigo-900 dark:bg-indigo-900 dark:text-indigo-200">
            happier            </mark>{" "}
             with the results
          </p>
          <div className="flex items-center mt-8 space-x-3">
            <div className="flex-shrink-0 overflow-hidden rounded-full w-14 h-14">
              <img
                alt="Avatar"
                src="https://images.unsplash.com/photo-1624224971170-2f84fed5eb5e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=100&h=100&crop=faces&q=80"
                loading="lazy"
              />
            </div>
            <div>
              <div className="text-lg font-medium text-subMain">
              Aaditya
              </div>
              <div className="text-gray-600 dark:text-gray-400">
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>





</>
    )
}
export default Review