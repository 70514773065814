import React from "react";
import { MdGrass } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import modal1 from '../Asstes/Modal-1.jpg'

const HomeCard = () => {
  return (
    <>
       <div data-aos="fade-down-right">
      <section className="text-gray-700 body-font border-t border-gray-200">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
       
          <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
        
            <img
              alt="feature"
              className="object-cover object-center h-full w-full"
              src={modal1}
            />
            
          </div>
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
            <div className="flex flex-col mb-10 lg:items-start items-center"></div>
            <div className="flex flex-col mb-10 lg:items-start items-center">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-subMain text-main mb-5">
                <MdGrass size={30} color="" />
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Health Products
                </h2>
                <p className="leading-relaxed text-base">
                  At Organicz, we specialize in organic products designed to
                  address various health issues, ensuring quality and
                  sustainability in every item we offer.
                </p>
              </div>
            </div>
            <div className="flex flex-col mb-10 lg:items-start items-center">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-subMain text-main mb-5">
              <FaUser  size={30} color="" />
             
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Organic
                </h2>
                <h4 className="my-4 text-lg text-gray-500">
                  Quality Organic Solutions
                </h4>
                <p className="leading-relaxed text-base">
                  Explore our range of organic products for health and wellness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
   
      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-4xl mb-4 font-medium text-main">
              Welcome to
              <br className="hidden lg:inline-block" />
              Organicz!
            </h1>
            <p className="mb-8 leading-relaxed">
              At Organicz, we specialize in organic products that promote <br />{" "}
              health and wellness, available on Amazon, Flipkart, <br />
              and other e-commerce platforms.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="border-2  border-main text-main font-semibold px-8 py-3 rounded-full  flex  hover:bg-white  ">
              Order on amazon
              </div>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=612,h=464,fit=crop/A0xVDJG5l1hR1D1o/generated/generated-AR0brxoE8Ni1DppB.png"
            />
          </div>
        </div>
      </section>
     
      
    </>
  );
};

export default HomeCard;
