import React from "react";
import AvterTwo from "../../Asstes/Product-Section-img/Avter.jpg"
import { FaRegStar } from "react-icons/fa";
const CardThree = ()=>{
    return(
        <>
  <div class="grid min-h-[140px] bg-SubTwo  w-full place-items-center overflow-x-scroll h-96  p-6 lg:overflow-visible ">

  <div className="flex items-start ">
  <div className="flex-shrink-0">
    <div className="inline-block relative">
      <div className="relative w-16 h-16 rounded-full overflow-hidden">
        <img
          className="absolute top-0 left-0 w-full h-full bg-cover object-fit object-cover"
          src={AvterTwo}
          alt="Profile picture"
        />
        <div className="absolute top-0 left-0 w-full h-full rounded-full shadow-inner" />
      </div>
      <FaRegStar className="fill-current text-white bg-green-600 rounded-full p-1 absolute bottom-0 right-0 w-6 h-6 -mx-1 -my-1" />
   
    </div>
  </div>
  <div className="ml-6">
    <p className="flex items-baseline">
      <span className="text-textOne font-bold">Neha</span>
      <span className="ml-2 text-green-600 text-xs">Verified Buyer</span>
    </p>
    <div className="flex items-center mt-1">
      <svg
        className="w-4 h-4 fill-current text-yellow-600"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
      </svg>
      <svg
        className="w-4 h-4 fill-current text-yellow-600"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
      </svg>
      <svg
        className="w-4 h-4 fill-current text-yellow-600"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
      </svg>
      <svg
        className="w-4 h-4 fill-current text-yellow-600"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
      </svg>
      <svg
        className="w-4 h-4 fill-current text-textOne"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
      </svg>
    </div>
    <div className="flex items-center mt-4 text-textOne">
      <div className="flex items-center">
        <span className="text-sm">Product Quality</span>
        <div className="flex items-center ml-2">
          <svg
            className="w-3 h-3 fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
        </div>
      </div>
      <div className="flex items-center ml-4">
        <span className="text-sm">Purchasing Experience</span>
        <div className="flex items-center ml-2">
          <svg
            className="w-3 h-3 fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-yellow-600"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-textOne"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
          <svg
            className="w-3 h-3 fill-current text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
          </svg>
        </div>
      </div>
    </div>
    <div className="mt-3">
      <span className="font-bold text-textOne">Sapien consequat eleifend!</span>
      <p className="mt-1 text-textOne">
      I have been using Organicz products for my health issues, and they have made a significant difference. Highly recommend their quality and effectiveness!
      </p>
    </div>
    <div className="flex items-center justify-between mt-4 text-sm text-textOne fill-current">
      <button className="flex items-center">
   
      </button>
   
    </div>
  </div>
</div>

  </div>
        </>
    )
}
export default CardThree;