import React from "react"

import porductimg1 from "../../Asstes/Product-Section-img-2/img-1.jpg"
import porductimg2 from "../../Asstes/Product-Section-img-2/img-2.jpg"

const ProductCardOne =()=>{
    return(
        <>
      <section className="py-24 relative bg-ProdcutColor place-items-center p-9">
      <h1 className="mb-1 text-main p-2 text-center text-5xl font-medium leading-tight text-primary mt-5">
          Organic Products
        </h1>
        <p className="text-sm text-subMain  text-center">
        Explore our range of health-focused organic products for <br /> <span className="text-center">wellness.</span> 
        </p>
        <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div className="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1">
          <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
  <div className="rounded overflow-hidden flex flex-col max-w-xl mx-auto">
    <a href="#">
      <img
        className="w-full "
        src={porductimg1}
        alt="Sunset in the mountains"
      />
    </a>
    <div data-aos="zoom-in-right" >

    <div className="relative -mt-16 px-10 pt-5 pb-16 bg-white m-10">
      <a
        href="#"
        className="font-semibold text-lg  hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2"
      >
       E-commerce Platforms
      </a>
      <p className="text-gray-500 text-sm">
      Shop our organic products on Amazon, Flipkart, and other e-commerce platforms for convenience.
      </p>
    
    </div>
    </div>
  </div>
</div>

<div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
  <div className="rounded overflow-hidden flex flex-col max-w-xl mx-auto">
    <a href="#">
      <img
        className="w-full"
        src={porductimg2}
        alt="Sunset in the mountains"
      />
    </a>
    <div data-aos="zoom-in-right" >
    <div className="relative -mt-16 px-10 pt-5 pb-16 bg-white m-10">
      <a
        href="#"
        className="font-semibold text-lg  hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2"
      >
        Natural Remedies
      </a>
      <p className="text-gray-500 text-sm">
      Discover natural remedies that promote health and wellness through our carefully curated organic products.
      </p>
    
    </div>
    </div>
  </div>
</div>

          </div>
        </div>
      </section>
  
        </>
    )
}
export default ProductCardOne