import "./App.css";
import Footer from "./compontens/Footer";
import Header from "./compontens/Header";
import Contact from "./pages/contact/Contact";
import Avater from "../src/Asstes/Avter.png"
import { Routes,Route } from 'react-router-dom';
import Home from "./pages/Home";
import Protfolio from "./pages/portfolio/Protfolio";
import Product from "./pages/Products/Product";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import NotFound from "./compontens/NotFound";
import Blog from "./pages/Blog/Blog";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

// import OrganicProduct from './pages/OrganicProduct';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <FloatingWhatsApp
        phoneNumber=" +91 8287486509"
        onNotification={1}
        accountName="organicz "
        statusMessage="online"
        avatar={Avater}
        showAvailableOnDesktop={true}
        showAvailableOnMobile={true}
        notificationDelay ={60}
      />
      
      <Routes>
  <Route path="/" element={<Home/>} />
  <Route path="/portfolio" element={<Protfolio/>} />
  <Route path="/product" element={<Product/>} />
  <Route path="/contact" element={<Contact/>} />
  <Route path="/Organic-blog" element={<Blog/>} />
  <Route path="*" element={<NotFound/>} />
 
 </Routes>
      
      <Header />
     
   
   
      <Footer />
    </>
  );
}

export default App;
