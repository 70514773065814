import React, { useEffect } from 'react'
import { GiHighGrass } from "react-icons/gi";
import { FaInstagram } from "react-icons/fa6"
import { IoMailOutline } from "react-icons/io5";
import { CiDeliveryTruck } from "react-icons/ci";
import CardOne from './CardOne';
import { MdSecurity } from "react-icons/md";
import HomeCard from './HomeCard';
import OurMission from './OurMission';
import Review from './Review';

import { Link, useNavigate } from 'react-router-dom';
import Contact from './contact/Contact';
const Home = () => {

  const navigate = useNavigate();
  const handleClick = () => {
    
    navigate('https://www.instagram.com/beorganicz/profilecard/?igsh=MXBpa3oyYjV6a2RiZQ%3D%3D');
  };
  return (
    <>
    
    <>
 

  <main className="pt-10 z-1  ">
 
    <section className="relative  bg-gradient-to-br from-blue-900 to-indigo-800 text-white overflow-hidden ">
      <div className="absolute inset-0 bg-black opacity-80" >
      <div
        className="absolute inset-0 bg-cover bg-center bg-fixed  h-90"
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/699422/pexels-photo-699422.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")'
        }}
        
      />
      </div>
      <div className="container mx-auto px-4 py-24 md:py-32 relative z-1">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left Side: Company Info */}
          <div className="w-full md:w-1/2 mb-12 md:mb-0">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
            Discover organicz Products 
              <br />
              for Your Health and Well-
              <br />
              being
            </h1>
            <p className="text-xl mb-8 text-gray-300">
            Shop Now on Amazon, Flipkart, and More!
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <Link
                to="/contact"
                className="bg-white text-blue-900 font-semibold px-8 py-5 rounded-full hover:bg-main transition duration-300 text-center"
              >
                Get Started
              </Link>
              <div className=" border   font-semibold  px-8 py-4 rounded-full align-top  cursor-pointer flex  justify-center  text-center    ">
             
             <Link  target="_blank" to="https://www.instagram.com/beorganicz/profilecard/?igsh=MXBpa3oyYjV6a2RiZQ%3D%3D"><FaInstagram className='hover:text-main transition duration-300' size={30} /> </Link> 
           <Link to="mailto:max.organiczindia@gmail.com?body=My custom mail body">  <IoMailOutline className='ml-8 hover:text-main transition duration-300 ' size={30} /></Link>   
              </div>
             
               
             
            </div>
          </div>
          {/* Right Side: Features */}
          <div className="w-full md:w-1/2 md:pl-12">
            <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-8 shadow-2xl">
              <h2 className="text-2xl font-semibold mb-6">Why MyCompany?</h2>
              <ul className="space-y-4">
                <li className="flex items-center">
                <GiHighGrass className='w-6 h-6 mr-3 text-main' />
              
                  <span> 100% Natural Processing</span>
                </li>
                <li className="flex items-center">
                <MdSecurity  className="w-6 h-6 mr-3 text-green-400"/>
              
                  <span>100% customer satisfaction</span>
                </li>
                <li className="flex items-center">
                  <CiDeliveryTruck className="w-7 h-7 mr-3 text-purple-400"/>
               
                  <span>On time delivery </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Decorative Element */}
      
    </section>
    {/* ... (rest of your main content) ... */}
  </main>
  {/* ... (previous script remains unchanged) ... */}
</>
{/* image--Product */}


<CardOne/>
<HomeCard/>
<OurMission/>
<Review/>

<Contact/>

    </>
  )
}

export default Home
