import React from "react";
import CardOne from "./CardOne";
import CardTwo from "./CardTwo";
import CardThree from "./CardThree";
import Contact from "../contact/Contact";

const Protfolio = ()=>{
    return(
        <>
     <CardOne/>
     <CardTwo/>
     <CardThree/>
     <Contact/>
        </>
    )
}
export default Protfolio;