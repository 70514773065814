import React from "react";
import logo from '../Asstes/Logo.png'
import { Link } from "react-router-dom";
import { FaSquareXTwitter,FaFacebookF,FaInstagram,FaSquareThreads } from "react-icons/fa6";


const Footer =()=>{
    return(
        <>
        <footer className="w-full  bg-dryFooter" >
  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    {/*Grid*/}
    <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-3 md:gap-8 py-10 max-sm:max-w-sm max-sm:mx-auto gap-y-8">
      <div className="col-span-full mb-10 lg:col-span-2 lg:mb-0">
        <Link
          to="/"
          className="flex justify-center lg:justify-start"
        >
           <img
          src={logo}
          className="h-12"
          alt="Flowbite Logo"
        />
        </Link>
        <p className="py-8 text-sm text-textOne lg:max-w-xs text-center lg:text-left">
          Trusted in more than 100 countries &amp; 5 million customers. Have any
          query ?
        </p>
        <Link
          to="/contact"
          className="py-2.5 px-5 h-9 block w-fit bg-main rounded-full shadow-sm text-xs text-white mx-auto transition-all  duration-500 hover:bg-indigo-700 lg:mx-0"
        >
          Contact us
        </Link>
      </div>
      {/*End Col*/}
      <div className="lg:mx-auto text-left ">
        <h4 className="text-lg text-white font-medium mb-7">Organicz</h4>
        <ul className="text-sm  transition-all duration-500">
          <li className="mb-6">
            <Link
           to="/"
              className="text-textOne hover:text-main"
            >
              Home
            </Link>
          </li>
          <li className="mb-6">
            <Link
            to="/portfolio"
              className=" text-textOne hover:text-main"
            >
              Portfolio
            </Link>
          </li>
          <li className="mb-6">
            <Link
                   to="/product"
              className=" text-textOne hover:text-main"
            >
              Products
            </Link>
          </li>
          <li>
            <Link
                to="/contact"
              className=" text-textOne hover:text-main"
            >
              contact
            </Link>
          </li>
        </ul>
      </div>
      {/*End Col*/}
      <div className="lg:mx-auto text-left ">
        <h4 className="text-lg text-textOne font-medium mb-7">Products</h4>
        <ul className="text-sm  transition-all duration-500">
          <li className="mb-6">
            <a
              href="javascript:;"
              className="text-textOne hover:text-main"
            >
            UI System
            </a>
          </li>
          <li className="mb-6">
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              Icons Assets
            </a>
          </li>
          <li className="mb-6">
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              Responsive Blocks
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              Components Library
            </a>
          </li>
        </ul>
      </div>
      {/*End Col*/}
      <div className="lg:mx-auto text-left">
        <h4 className="text-lg text-textOne font-medium mb-7">Resources</h4>
        <ul className="text-sm  transition-all duration-500">
          <li className="mb-6">
            <a
              href="javascript:;"
              className="text-textOne hover:text-main"
            >
              FAQs
            </a>
          </li>
          <li className="mb-6">
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              Quick Start
            </a>
          </li>
          <li className="mb-6">
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              Documentation
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              User Guide
            </a>
          </li>
        </ul>
      </div>
      {/*End Col*/}
      <div className="lg:mx-auto text-left">
        <h4 className="text-lg text-textOne font-medium mb-7"> <Link    to="Organic-blog">Blogs</Link> </h4>
        <ul className="text-sm  transition-all duration-500">
          <li className="mb-6">
            <a
              href="javascript:;"
              className="text-textOne hover:text-main"
            >
              News
            </a>
          </li>
          <li className="mb-6">
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              Tips &amp; Tricks
            </a>
          </li>
          <li className="mb-6">
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              New Updates
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              className=" text-textOne hover:text-main"
            >
              Events
            </a>
          </li>
        </ul>
      </div>
    </div>
    {/*Grid*/}
    <div className="py-7 border-t border-gray-200">
      <div className="flex items-center justify-center flex-col lg:justify-between lg:flex-row">
        <span className="text-sm text-textOne ">
          ©<Link to="/">Organic </Link> 2024, All rights
          reserved.
        </span>
        <div className="flex mt-4 space-x-4 sm:justify-center lg:mt-0 ">
          <Link target="_blank"
            to="https://x.com/i/flow/login?redirect_after_login=%2Fbeorganicz"
            className="w-9 h-9 rounded-full bg-main flex justify-center items-center hover:bg-subMain"
          >
        <FaSquareXTwitter size={20}/>
          </Link>
          <Link target="_blank"
            to="https://www.threads.net/@beorganicz?xmt=AQGzGLls1QwFvdGFphmQExNlqm3FMtbQxNcCHmH7ZqJUON4"
            className="w-9 h-9 rounded-full bg-main flex justify-center items-center hover:bg-subMain"
          >
         <FaSquareThreads size={20}/> 
          </Link>
          <Link target="_blank"
            to="https://www.instagram.com/beorganicz/profilecard/?igsh=MXBpa3oyYjV6a2RiZQ%3D%3D" 
            className="w-9 h-9 rounded-full bg-main flex justify-center items-center hover:bg-subMain"
          >
            <FaInstagram size={20}/>
          </Link>
          <Link target="_blank"
            to="https://www.facebook.com/profile.php?id=61566549011355&rdid=v8J7s9lmzL0ouHMf&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FLoPmL2GBF7L1pwsE%2F"
            className="w-9 h-9 rounded-full bg-main flex justify-center items-center hover:bg-subMain"
          >
          <FaFacebookF size={20}/>
          </Link>
        </div>
      </div>
    </div>
  </div>
</footer>

        </>
    )
}
export default Footer