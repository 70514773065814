import React from "react";
import { GrAmazon } from "react-icons/gr";
import porductimg7 from "../../Asstes/Product-Section-img/img-7.jpg"
import porductimg8 from "../../Asstes/Product-Section-img/img-8.jpg"
import porductimg9 from "../../Asstes/Product-Section-img/img-9.jpg"
import porductimg10 from "../../Asstes/Product-Section-img/img-10.jpg"

const CardTwo = ()=>{
    return(
        <>
                   <div className=" w-full">
  <section className="bg-subMain dark:bg-gray-900">
    <div className="container p py-10 mx-auto">
      
      <h1 className="text-3xl font-semibold text-textOne capitalize lg:text-4xl dark:text-white">
      Organic Health {''}
        <span className="underline decoration-main">Solutions </span>
      </h1>
      <p className="mt-4 text-gray-500 xl:mt-6 dark:text-gray-300">
      We provide organic products tailored for various health issues, ensuring quality and effectiveness.
      </p>
      <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
      <div className="mx-auto mt-1 w-80 transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow-md duration-300 hover:scale-105 hover:shadow-lg">
    <img
      className="h-60 w-full object-cover object-center"
      src={porductimg7}
      alt="Product Image"
    />
    <div data-aos="zoom-in-up">
    <div className="p-4">
      <h2 className="mb-2 text-lg font-medium dark:text-white text-gray-900">
      Health Consultation Services
      </h2>
      <p className="mb-2 text-base dark:text-gray-300 text-gray-700">
      Expert advice on choosing the right organic products for your specific health needs.
      </p>
      <div className="flex items-center">
        <p className="mr-2 text-lg font-semibold text-gray-900 dark:text-white">
          200₹
        </p>
        <p className="text-base  font-medium text-gray-500 line-through dark:text-gray-300">
        300₹
        </p>
        <p className="ml-auto text-base font-medium text-green-500">20% off</p>
      </div>
    </div>
    </div>
  </div>
        <div className="mx-auto mt-1 w-80 transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow-md duration-300 hover:scale-105 hover:shadow-lg">
    <img
      className="h-60 w-full object-cover object-center"
      src={porductimg8}
      alt="Product Image"
    />
     <div data-aos="zoom-in-up">
    <div className="p-4">
      <h2 className="mb-2 text-lg font-medium dark:text-white text-gray-900">
      Health Consultation Services
      </h2>
      <p className="mb-2 text-base dark:text-gray-300 text-gray-700">
      Expert advice on choosing the right organic products for your specific health needs.
      </p>
      <div className="flex items-center">
        <p className="mr-2 text-lg font-semibold text-gray-900 dark:text-white">
        200₹
        </p>
        <p className="text-base  font-medium text-gray-500 line-through dark:text-gray-300">
        300₹
        </p>
        <p className="ml-auto text-base font-medium text-green-500">20% off</p>
      </div>
    </div>
    </div>
  </div>
       

        <>
  {/* component */}
  <div className="mx-auto mt-1 w-80 transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow-md duration-300 hover:scale-105 hover:shadow-lg">
    <img
      className="h-60 w-full object-cover object-center"
      src={porductimg9}
      alt="Product Image"
    />
    <div data-aos="zoom-in-up">
    <div className="p-4">
      <h2 className="mb-2 text-lg font-medium dark:text-white text-gray-900">
      Health Consultation Services
      </h2>
      <p className="mb-2 text-base dark:text-gray-300 text-gray-700">
      Expert advice on choosing the right organic products for your specific health needs.
      </p>
      <div className="flex items-center">
        <p className="mr-2 text-lg font-semibold text-gray-900 dark:text-white">
        200₹
        </p>
        <p className="text-base  font-medium text-gray-500 line-through dark:text-gray-300">
        300₹
        </p>
        <p className="ml-auto text-base font-medium text-green-500">20% off</p>
      </div>
    </div>
    </div>
  </div>
</>



      </div>
    </div>
  </section>
 
  </div>
        </>
    )
}
export default CardTwo;